





































































import { Component, Prop, Provide, Vue } from 'vue-property-decorator'

@Component
export default class Dialog extends Vue {
    visible = {
        val: false
    }
    @Provide('visible')
    visibleVal = this.visible
    // props
    @Prop({ default: '' }) title!: string //弹窗标题
    @Prop({ default: '确认要删除？' }) content!: string //弹窗内容
    @Prop({ default: '确认' }) confirmButtonText!: string //确认按钮内容
    @Prop({ default: '取消' }) cancelButtonText!: string | boolean //取消按钮内容
    @Prop({ default: '400px' }) width!: string | number //弹窗的宽度
    @Prop({ default: '40vh' }) top!: string | number //弹窗的距离顶部位置
    @Prop({ default: false }) disabled!: boolean //是否禁用
    @Prop({ default: false }) async!: boolean //是否开启异步关闭
    @Prop({ default: true }) clickModalClose!: boolean // 点击遮罩层关闭对话窗口
    // data
    
    // methods
    handleEvent(type: 'cancel' | 'confirm') {
        this.$emit(type)
        if (!this.async || type === 'cancel') this.close()
    }

    onTrigger() {
        if (this.disabled) return
        this.open()
    }

    close() {
        this.visible.val = false
        this.$emit('close')
    }
    open() {
        this.visible.val = true
        this.$emit('open')
    }
}
